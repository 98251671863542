export enum ActionType
 {
  INIT_PRODUCT_CATALOG         = 'INIT_PRODUCT_CATALOG'         ,
  SET_PRODUCT_CATALOG          = 'SET_PRODUCT_CATALOG'          ,
 }

export interface Characteristic {
  id:                 number ,
  name:               string ,
  kind:               string ,
  unit:               string ,
  value:              string 
}


export interface Discount {
  type           : string;
  condition      : string;   //  si type === statut, alors nom du statut minimale, si type === evenement, alors nom de l'événement
  value          : string;   //  "x en valeur normale ou x %"
  active         : boolean;
  priceToApplyOn : string;
}

export interface Price {

  id:                            number ,
  name:                          string ,
  requiredStatus:                string ,
  comment:                       string ,
  code:                          string ,
  active:                        boolean,
  creationDate:                  string ,
  basePrice:                     number ,
  taxRate:                       number ,
  priceWithTax:                  number,
  discounts:                     Discount [];
  priceToPay:                    number
}

export interface ProductVariant {

  id:                 number ,
  name:               string ,
  complement:         string ,
  description:        string ,  
  barcode:            string ,  
  active:             boolean;
  creationDate:       string ,
  businessUnitCode:   string ,
  productVariantCode: string ,
  stockAvailable:     number,
  stockBooked:        number,
  stockAssigned:      number,
  stockThreshold:     number,
  characteristics:    Characteristic [],
  prices:             Price []
}

export interface Product {

  id:                   number ,
  name:                 string ,
  catchword:            string ,
  code:                 string ,
  active:               boolean,
  requiredStatus:       string ,
  paymentRequired:      boolean ,
  kind:                 string ,   // Pour PhysicalItem, VideoMasterClass...
  description:          string ,  
  creationDate:         string ,
  variants:             ProductVariant [] ,
  characteristics:      Characteristic []
}

export const initializedPrice = (): Price => ({
  id:                            0,
  name:                          '',
  requiredStatus:                '',
  comment:                       '',
  code:                          '',
  active:                        true,
  creationDate:                  '',
  basePrice:                     0,
  taxRate:                       0,
  priceWithTax:                  0,
  discounts:                     [],
  priceToPay:                    0,
});

export const initializedProductVariant = (): ProductVariant => ({
  id:                 0 ,
  name:               '' ,
  complement:         '',
  description:        '' ,  
  barcode:            '' ,  
  active:             false,
  creationDate:       '' ,
  businessUnitCode:   '' ,
  productVariantCode: '' ,
  stockAvailable:     0,
  stockBooked:        0,
  stockAssigned:      0,
  stockThreshold:     0,
  characteristics:     [],
  prices:          [initializedPrice()],
});

export interface ProductCategory {
  id:              number
  name:            string
  rank:            number
  creationDate:    Date;
  visible:         boolean;
  products: Product[]
 }

export interface ProductCatalog
 {
  categories: ProductCategory[]
 }


export const initializedProductCategory = (): ProductCategory => ({
  id:                0,
  name:              '',
  rank:              0,
  creationDate:      new Date(),
  visible:           false,
  products:          []
});

export const initializedProduct = (): Product => ({
  id:                0,
  name:              '',
  catchword:         '',
  code:              '',
  active:            true,
  creationDate:      '',
  requiredStatus:    'visitor',
  paymentRequired:   false,
  kind:              'Abonnement',
  description:       '',
  variants:          [initializedProductVariant()],
  characteristics:   [],
});




export interface actionInitProductCatalog
 {
  type:                   ActionType.INIT_PRODUCT_CATALOG;
 }

export interface actionSetProductCatalog
 {
  type:                   ActionType.SET_PRODUCT_CATALOG;
  newCatalog:             ProductCatalog;
 }

export type Action = actionInitProductCatalog        |
                     actionSetProductCatalog;

export type DispatchTypeInitProductCatalog = ( args: actionInitProductCatalog ) => actionInitProductCatalog
export type DispatchTypeSetProductCatalog  = ( args: actionSetProductCatalog  ) => actionSetProductCatalog
					 
