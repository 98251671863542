import   React                                  , 
       { useState                               ,
	     useEffect                              }  from 'react';

import   classNames                                from 'classnames';

import { useLocation                            ,
         useNavigate                            }  from 'react-router-dom';

import { Dispatch                               }  from 'redux';
import { useDispatch                            }  from 'react-redux';

import { parse, differenceInYears, isValid } from 'date-fns';

import   styles                                    from './shop.module.scss';

import { GlobalMenu                             }  from '../../menu/global/global';
import { Footer                                 }  from '../../menu/footer/footer';
import { TopShifter                             }  from '../../menu/top_shifter/top_shifter';
import { CookiesManager                         }  from '../../cookie/manager';

import { ProductCatalog                         }  from './blocks/catalog/product_catalog';
import { ProductSheet                           }  from './blocks/catalog/product_sheet';
import { ProductVariantChoice                   }  from './blocks/catalog/product_variant_choice';

import { setProductCatalog                      }  from '../../../datas/productManagment/actionCreators';
import { Product, initializedProduct                                }  from '../../../datas/productManagment/actionTypes';

import { initCart                               }  from '../../../datas/cartManagment/actionCreators';
import { initAuthentication                     }  from '../../../datas/authenticationManagment/actionCreators';
import { initUser                               }  from '../../../datas/userManagment/actionCreators';
		 
import { useTypedSelector                       }  from '../../../datas/useTypeSelector';

import { getProductCatalogInfos                 ,
         ProductCatalogInfos                    }  from '../../../network/api_product';

import { postEvent                              }  from '../../../network/api_events_stats';

import { postInformationRequest                 }  from '../../../network/api_urban_tour';

import { INTERNAL_LINKS                         }  from '../../../network/urls';

import { WindowWithField                        }  from '../../widget/window/window_with_field';
import { WindowWithForm                         }  from '../../widget/window/window_with_form';
import { Window                                 }  from '../../widget/window/window';

import { retrieveUserAgentInformations          }  from '../../../network/utils';	

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export const Shop = () => {

  const location = useLocation();
  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();
  
  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment );
  const { catalog        } = useTypedSelector ( (state) => state.productManagment );
  const { cart           } = useTypedSelector ( (state) => state.cartManagment );

  const nullProduct: Product = initializedProduct ();

  const [ showSheet              , setShowSheet              ] = useState ( false );
  const [ showVariantChoice      , setShowVariantChoice      ] = useState ( false );
  const [ showRequestInformation , setShowRequestInformation ] = useState ( false );
  
  const [ alertWindowDeprecatedTokenVisible, setAlertWindowDeprecatedTokenVisible] = useState ( false );
  
  const [ currentUrbanTour       , setCurrentUrbanTour       ] = useState ( "" );
  const [ currentProduct         , setCurrentProduct         ] = useState ( nullProduct );
  const [ version                , setVersion                ] = useState ( 0 );

  const [ showWindow            , setShowWindow         ] = useState ( false );
  const [ windowMessage         , setWindowMessage      ] = useState ( <></> );
  
  var productcatalogInfos: ProductCatalogInfos = {
      code:    0 ,
	  comment: "" ,
      catalog: {
        categories: []
      }
    }; 
	
  useEffect(() => {
	
    window.scroll(0, 0);

	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    init ();

  }, [] );

  useEffect(() => {
	
	console.log (currentProduct)

  }, [authentication, catalog, version] );

  const init = async () => {
  
    productcatalogInfos = await getProductCatalogInfos ( );

	console.log (productcatalogInfos)

	dispatch ( setProductCatalog ( productcatalogInfos.catalog ) );
	
	if ( authentication.login !== cart.email )   ///    authentication.login !== "" && authentication.login !== cart.email
	                                             ///    authentication.login === "" && "" !== cart.email
	 {
	  dispatch ( initCart())
	 }
	
	setVersion ( version + 1 )
  }

  const handleShowSheet = (product: Product) => {
  
    if ( product.kind !== "Urban Tour Masterclass" )
	      {
           setCurrentProduct ( product )
           setShowSheet (true)
		  }
	 else {
	            if ( product.name === "Lovelight Urban Tour spécial Nice" )
		         {
                  navigate ( `${INTERNAL_LINKS.UrbanTour_Nice}` );
				 }
		   else if ( product.name === "Lovelight Urban Tour spécial Rome" )
		         {
                  navigate ( `${INTERNAL_LINKS.UrbanTour_Rome}` );
				 }
		   else 
		         {
                  setCurrentUrbanTour ( product.name )
                  setShowRequestInformation ( true );
				 }
    	  }
  }
  
  const handleShowVariantChoice = (product: Product) => {
  
    setCurrentProduct (product)
    setShowVariantChoice (true)
  }

  const handleHideSheet = () => {
  
    setCurrentProduct (nullProduct)
    setShowSheet (false)
  }

  const handleHideVariantChoice = () => {
  
    setShowVariantChoice (false)
  }

  const handleShowInformationRequest = (subject: string) => {
  
    setCurrentUrbanTour ( subject )
    setShowRequestInformation ( true );
  }

  const closeRequestInformationWindowWithCancelButton = () => {
  
    setShowRequestInformation ( false );
    setCurrentUrbanTour ( "" )
  }

  const closeRequestInformationWindowWithValidButton = async ( firstname:   string ,
                                                               lastname:    string ,
                                                               age:         number ,
                                                               email:       string ,
                                                               phoneNumber: string ,
                                                               city:        string ) => {

    setShowRequestInformation ( false );

    const response = await postInformationRequest ( firstname, lastname, age, email, phoneNumber, city, `${currentUrbanTour}` )
	
    switch ( response.code )
	   {
	    case 1    : setWindowMessage ( <>Nous avons bien reçu votre demande, elle sera traitée sous 24h (jours ouvrés)</> );
                    setShowWindow ( true );
		
		            break;
		
		default   : setWindowMessage ( <>Votre demande n'a pas pu être envoyée, veuillez recommancer</> );
                    setShowWindow ( true );

	   }

    setCurrentUrbanTour ( "" )
  }

  const handleUpdateShop = () => {
  
 
    setVersion ( version + 1 )
  }

  const showAlertWindowDeprecatedToken = () => {
  
    setAlertWindowDeprecatedTokenVisible ( true );
  }

  const closeAlertWindowDeprecatedTokenWithCancelButton = () => {

    setAlertWindowDeprecatedTokenVisible ( false );
	
	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Home}` );
   }

  const closeAlertWindowDeprecatedTokenWithValidButton = () => {

    setAlertWindowDeprecatedTokenVisible ( false );

	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Login}` );
  }
 
  function calculateAge(): string {
  
    const birthDate = parse(user.birthday, 'dd/MM/yyyy', new Date());
 
    if (!isValid(birthDate)) 
	 {
      throw new Error("Le format de la date de naissance est invalide.");
     }

    const age = differenceInYears(new Date(), birthDate);
  
    return age.toString();  
  }

  const handleCloseButtonClick = () => {

	setWindowMessage ( <></>);
    setShowWindow ( false );
  }
  

  return (
            <>
		      
 		      <CookiesManager />
			  <GlobalMenu version={version}/>
              <TopShifter/>

		      {
			   showSheet &&
			   
			   ( <ProductSheet product                = {currentProduct}
			                   hideSheet              = {handleHideSheet} 
							   showRequestInformation = {handleShowInformationRequest}
							   showVariantChoice      = {handleShowVariantChoice}
							   updateShop             = {handleUpdateShop} 
							   showAlertWindowDeprecatedToken = {showAlertWindowDeprecatedToken} /> )
			  }
		 
		      {
			   showVariantChoice &&
			   
			   ( <ProductVariantChoice product    = {currentProduct}
			                           hideChoice = {handleHideVariantChoice} 
									   updateShop = {handleUpdateShop} 
							           showAlertWindowDeprecatedToken = {showAlertWindowDeprecatedToken}  /> )
			  }
		 
	          {
	           showRequestInformation &&
	           ( < WindowWithForm  message               = { <><span className = {classNames(styles.bold_text)}>Je souhaite recevoir par mail des information concernant l'offre "{currentUrbanTour}".</span><br/></> }
                                   messageType           = "normal"
                                   onClickOnCancelButton = {closeRequestInformationWindowWithCancelButton}
                                   onClickOnValidButton  = {closeRequestInformationWindowWithValidButton}
                                   cancelButtonName      = "NON"
                                   validButtonName       = "OUI"   
							       stylesGridInfo        = {classNames(styles.grid_info)} 
								   initialFirstname      = {user.firstname}
								   initialLastname       = {user.lastname}
								   initialAge            = {calculateAge()}
								   initialEmail          = {user.email}
								   initialPhoneNumber    = {user.phoneNumber}
								   initialCity           = {user.city} /> 
				)
	          }

			  {
	           alertWindowDeprecatedTokenVisible 
			   &&
	           ( 
			    < Window message               = { <><span className = {classNames(styles.bold_text)}>Votre session a expiré, vous devez vous reconnecter.</span><br/><br/>Confirmer ?<br/></> }
                         messageType           = "normal"
                         onClickOnCancelButton = {closeAlertWindowDeprecatedTokenWithCancelButton}
                         onClickOnValidButton  = {closeAlertWindowDeprecatedTokenWithValidButton}
                         cancelButtonName      = "NON"
                         validButtonName       = "OUI"   /> 
			   )
	          }
		 
              {
			    showWindow  &&
                (
				  < Window message              = {windowMessage}
				           onClickOnCloseButton = {handleCloseButtonClick} />
				)				
			  }
			   
              <div className = {classNames(styles.root)} >

                { catalog && ( < ProductCatalog showSheet         = {handleShowSheet} 
				                                showVariantChoice = {handleShowVariantChoice}  
												updateShop        = {handleUpdateShop} 
							                    showAlertWindowDeprecatedToken = {showAlertWindowDeprecatedToken} /> ) }
			  

			  
			
			  
              </div>			 

			  <Footer/>

            </>
        );
		
		
};

export default Shop;
