import   React                          , 
       { useState                       ,
	     useEffect                      }  from 'react';

import   classNames                        from 'classnames';

import { useNavigate, useLocation                    }  from 'react-router-dom';
import { Dispatch                       }  from 'redux';
import { useDispatch                    }  from 'react-redux';

import   styles                            from './cart_content.module.scss';

import { GlobalMenu                     }  from '../../menu/global/global';
import { Footer                         }  from '../../menu/footer/footer';
import { TopShifter                     }  from '../../menu/top_shifter/top_shifter';
import { CookiesManager                 }  from '../../cookie/manager';

import { BankChoice                     }  from '../../block/payment/bank_choice';

import { Product                        ,
         ProductVariant                 ,
		 initializedProduct , 
		 initializedProductVariant}  from '../../../datas/productManagment/actionTypes';

import {  Item                          }  from '../../../datas/cartManagment/actionTypes';

import { initCart                       , 
         addItem                        ,
         removeItem                     , 
         changeQuantity                 }  from '../../../datas/cartManagment/actionCreators';

import { initAuthentication             }  from '../../../datas/authenticationManagment/actionCreators';
import { initUser                       }  from '../../../datas/userManagment/actionCreators';

import { getProductCatalogInfos                 ,
         ProductCatalogInfos                    }  from '../../../network/api_product';
		 
import { setProductCatalog                      }  from '../../../datas/productManagment/actionCreators';
		 
import { removeItemFromUserCart         ,
         changeQuantityOfItemInUserCart }  from '../../../network/api_cart';
		 
import { useTypedSelector               }  from '../../../datas/useTypeSelector';

import { WWW_URLS                       , 
         INTERNAL_LINKS                 }  from '../../../network/urls';

import { isTokenValid                   }  from '../../../network/utils'; 

import { Window                         }  from '../../widget/window/window';

  
import { postPayment        }  from '../../../network/api_payment';
 
import { postEvent          }  from '../../../network/api_events_stats';

export const CartContent = () => {

  const dispatch: Dispatch<any> = useDispatch();
  const navigate  = useNavigate();
  const   location                = useLocation();


  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { cart           } = useTypedSelector ( (state) => state.cartManagment           );
  const { catalog        } = useTypedSelector ( (state) => state.productManagment        );
  const { user           } = useTypedSelector ( (state) => state.userManagment           );

  const [ version             , setVersion             ] = useState (   0   );
  const [ alertWindowVisible1 , setAlertWindowVisible1 ] = useState ( false );
  const [ alertWindowVisible2 , setAlertWindowVisible2 ] = useState ( false );
  const [ showBankChoice      , setShowBankChoice      ] = useState ( false );
  const [ alertWindowDeprecatedTokenVisible, setAlertWindowDeprecatedTokenVisible] = useState ( false );
  const [ paramsProcessed, setparamsProcessed ] = useState ( false );

  var productcatalogInfos: ProductCatalogInfos = {
      code:    0 ,
	  comment: "" ,
      catalog: {
        categories: []
      }
    };
	
  let totalPrice :         number  =  0 ;
  let physicalItemPresent: boolean = false;
  let priceForPremium:     boolean = false;

  const shippingFees: number = 3;

  useEffect(() => {

    if ( version === 0 ) {init ();}

  	if ( authentication.login !== "" )
	 {
	  if ( isTokenValid ( authentication.token ) === false )
	   {
	    showAlertWindowDeprecatedToken ()
	   }
     }
	 
	const productCode = new URLSearchParams(location.search).get("product");
 
    console.log ("===============product", productCode)
 
    if ( productCode !== null && paramsProcessed === false )
	 {
	  const productToAdd = searchProduct (productCode);
	  const productVariantCode: string = productToAdd.variants[0].productVariantCode;
	  const priceCode: string = productToAdd.variants[0].prices[0].code;

      console.log ("AJOUT ", productToAdd, productVariantCode, priceCode)

      console.log ( productToAdd )

       dispatch ( addItem ( productCode        ,
                            productVariantCode ,
			   	  	        priceCode          ,
                            1           ) )
							
	   setparamsProcessed (true)
	 }
 

	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    window.scroll(0, 0);

  }, [version]);



 const init = async () => {
  
    productcatalogInfos = await getProductCatalogInfos ( );

	console.log ("--------", productcatalogInfos)

	dispatch ( setProductCatalog ( productcatalogInfos.catalog ) );
	
	// if ( authentication.login !== cart.email )   ///    authentication.login !== "" && authentication.login !== cart.email
	                                             //    authentication.login === "" && "" !== cart.email
	 // {
	  // dispatch ( initCart())
	 // }
	
	 setVersion ( version + 1 )
  }

  const searchProduct = ( productCode: string ): Product => {
 
    console.log ("searchProduct", productCode,catalog) 
 
    let foundProduct: Product = initializedProduct ()
	
	let found:boolean = false;
	
	for ( let cat = 0; cat < catalog.categories.length; cat++)
	 {
	  for ( let p = 0; p < catalog.categories[cat].products.length; p++)
	   {
	    if ( catalog.categories[cat].products[p].code === productCode )
		 {
		  foundProduct = catalog.categories[cat].products[p]
		  found = true;
		  break;
		 }
	   }
	   
	  if ( found )
	   {
	    break;
	   }
	 }
	 
	    console.log ("searchProduct found=", foundProduct) 
 
    return foundProduct
  }

  const searchProductVariant = ( product:            Product ,
                                 productVariantCode: string  ): ProductVariant => {
 
    let foundProductVariant: ProductVariant = initializedProductVariant ();
	
	for ( let v = 0; v < product.variants.length; v++)
	 {
	  if ( product.variants[v].productVariantCode === productVariantCode )
	   {
		foundProductVariant = product.variants[v]
		break;
	   }
	 }
	 
    return foundProductVariant
  }


  const searchImage = ( product:        Product        ,
                        productVariant: ProductVariant ): string => {
 
    let imgFile = "";
	
	for ( let c = 0; c < productVariant.characteristics.length; c++)
	 {
	  if ( productVariant.characteristics[c].kind === "Photo" )
	   {
	    imgFile = productVariant.characteristics[c].value;
		break;
	   }
	 }
	 
    if ( imgFile === "" )
     {
  	  for ( let c = 0; c < product.characteristics.length; c++)
	   {
	    if ( product.characteristics[c].kind === "Photo" )
	     {
	      imgFile = product.characteristics[c].value;
		  break;
	     }
	   }
     }
	 
    if ( imgFile === "" )
     {
	  for ( let v = 0; v < product.variants.length; v++)
	   {
  	    for ( let c = 0; c < product.variants[v].characteristics.length; c++)
	     {
	      if ( product.variants[v].characteristics[c].kind === "Photo" )
	       {
	        imgFile = product.variants[v].characteristics[c].value;
		    break;
	       }
	     }
	  
        if ( imgFile !== "" )
         {
	      break;
	     }	  
	   }
     }
	 
    return imgFile
  }
 
  const addOneProductToCurrentCart = async ( itemNumber: number ) => {
  
     // Dans ce cas de figure, on suppose qu'il n'y a qu'un seul variant
	 // Si la personne est connecté, on regarde s'il y a un prix associé à son statut
	
     const item: Item = cart.items[itemNumber]
	
	 const productCode: string        = item.productCode;
	 const productVariantCode: string = item.productVariantCode;
	 const quantity: number           = item.quantity + 1;
	 
	      console.log ( "addOneProductToCurrentCart" , productCode ,productVariantCode, quantity)

     dispatch ( changeQuantity ( productCode        ,
                                 productVariantCode ,
		                         quantity           ) )
										
	 if ( authentication.login !== "" )
	  {
	   await changeQuantityOfItemInUserCart ( authentication.login ,
		                                      authentication.token ,
		                                      cart.purchaseId      ,
											  productCode          ,
                                              productVariantCode   ,
		                                      quantity             )
	  }
	  
	 setVersion ( version+1 )
  }
  
  const removeOneProductToCurrentCart = async ( itemNumber: number ) => {
  
     // Dans ce cas de figure, on suppose qu'il n'y a qu'un seul variant
	 // Si la personne est connecté, on regarde s'il y a un prix associé à son statut
	 
     const item: Item = cart.items[itemNumber]
	
	 const productCode: string        = item.productCode;
	 const productVariantCode: string = item.productVariantCode;
	 const quantity: number           = item.quantity - 1;

     console.log ( "removeOneProductToCurrentCart" , productCode ,productVariantCode, quantity)

	 if ( quantity <= 0 )
	       {
	        dispatch ( removeItem ( productCode        ,
                                    productVariantCode ) )
									
			if ( authentication.login !== "" )
			 {
			  await removeItemFromUserCart ( authentication.login ,
			                                 authentication.token ,
											 cart.purchaseId      ,
			                                 productCode          ,
                                             productVariantCode   )
			 }
		   }
	  else {
	        dispatch ( changeQuantity ( productCode        ,
                                        productVariantCode ,
					                    quantity           ) )
										
			if ( authentication.login !== "" )
			 {
			  await changeQuantityOfItemInUserCart ( authentication.login ,
			                                         authentication.token ,
													 cart.purchaseId      ,
			                                         productCode          ,
                                                     productVariantCode   ,
					                                 quantity             )
			 }
		
		   }
	 
 	 setVersion ( version+1 )

  }

  const handlePlusButton = ( num: number ) => {
  
      console.log ( "handlePlusButton", num )

  
  	if ( authentication.login !== "" )
	      {
	       if ( isTokenValid ( authentication.token ) === false )
	        {
	         showAlertWindowDeprecatedToken ()
	        }
			else {
           addOneProductToCurrentCart ( num )
		  }
          }
	 else {
           addOneProductToCurrentCart ( num )
		  }
  }
 
  const handleMinusButton = ( num: number ) => {
  
    console.log ( "handleMinusButton", num )
  
  	if ( authentication.login !== "" )
	      {
	       if ( isTokenValid ( authentication.token ) === false )
	        {
	         showAlertWindowDeprecatedToken ()
	        }
			else {
			removeOneProductToCurrentCart ( num )
			}
          }
	 else {
           removeOneProductToCurrentCart ( num )
          }
  }
 
  const renderCartItem = ( itemNumber: number ): JSX.Element => {
  
    const item: Item = cart.items[itemNumber]
   
	const product        = searchProduct        ( item.productCode ) 
	const productVariant = searchProductVariant ( product , item.productVariantCode ) 
	  
	physicalItemPresent = physicalItemPresent || ( product.kind === "Physical Item" )  
	
    if ( authentication.token !== null )
	 {
      priceForPremium = ( user.status === 'Premium' )
	 }
	
	const unitPrice:  number = productVariant.prices[0].priceToPay  //priceForPremium ?  ( productVariant.prices.length>1 ? productVariant.prices[1].priceToPay : productVariant.prices[0].priceToPay ) : productVariant.prices[0].priceToPay
	const priceToPay: number = item.quantity * unitPrice

    const urlImage = `${WWW_URLS.ProductThumbnail}${searchImage(product, productVariant)}`;

    totalPrice += priceToPay 
  
  
    return (
	
	  <>
	    <div className = {classNames(styles.grid)} >
		
		  <div className = {classNames(styles.item_image)} >
	        <div className={classNames(styles.product_image)}>
              <img src = { urlImage }
                   alt = { urlImage } />
			</div>
  		  </div>

		  <div className = {classNames(styles.item_name,styles.text)} >
		    {productVariant.name}
		  </div>
		
		  <div className = {classNames(styles.item_unit_price,styles.text)} >
		    <div className = {classNames(styles.text,styles.right_align)} >
  	        {`${(unitPrice/100).toFixed(2)} €`}
		  </div>
		  </div>

		  <div className = {classNames(styles.quantity,styles.text)} >
		  
		   <div className = {classNames(styles.centered_block)} >
		  
  	        <button className = "relative inline-flex h-5 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		            onClick = {() => handleMinusButton(itemNumber)} >
			 
			 <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#FFFF00] px-1 py-1 text-xl font-medium text-black backdrop-blur-3xl">
              -
             </span>
          
    		</button>
			
			&nbsp;&nbsp;{item.quantity}&nbsp;&nbsp;
			
  	        <button className = "relative inline-flex h-5 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		            onClick = {() => handlePlusButton(itemNumber)} >
			 
			 <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
             <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#FFFF00] px-1 py-1 text-xl font-medium text-black backdrop-blur-3xl">
              +
             </span>
          
    		</button>

		   </div>
		   
		  </div>

		  <div className = {classNames(styles.item_price,styles.text)} >
		    <div className = {classNames(styles.text,styles.right_align)} >
    	        {`${(priceToPay/100).toFixed(2)} €`}
		    </div>
		  </div>

		</div>
		
          <br/>
		  <br/>
	  </>
	
	)
  }

  const renderCart = (): JSX.Element => {
  
 
    const items: JSX.Element [] = []
  
    for ( let i=0 ; i<cart.items.length ; i++ )
	 {
	  items.push ( <><div>{renderCartItem(i)}</div></> )
	 }
  
    return (
	
	  <>
	    {items}
	  </>
	
	)
  }
 
  const renderTotal = (): JSX.Element => {
  
    return (
	
	  <>
	    <br/>
		<hr/>
		<br/>
		
	    <div className = {classNames(styles.grid)} >
		
		  <div className = {classNames(styles.item_image)} >
		  
		  </div>

		  <div className = {classNames(styles.privileges_premium,styles.text)} >
		  { priceForPremium     && <p className = {classNames(styles.text)} >On a appliqué les prix réservés aux membres premium</p> }
		  </div>
		
		  <div className = {classNames(styles.item_unit_price,styles.text)} >
		  </div>

		  <div className = {classNames(styles.quantity,styles.text)} >
		   <div>
		     { physicalItemPresent ? "Sous-total :" : "Total :" }
 		   </div>
		  </div>

		  <div className = {classNames(styles.item_price,styles.text)} >
		    <div className = {classNames(styles.text,styles.right_align)} >
   	          {(totalPrice/100).toFixed(2)} €
		    </div>
		  </div>

		</div>
	  </>
	
	)
  }

  const renderTotalWithShippingFees = (): JSX.Element => {
  
    if ( physicalItemPresent )
  
    return (
	
	  <>
	    <br/>
		
	    <div className = {classNames(styles.grid)} >
		
		  <div className = {classNames(styles.item_image)} >
		  </div>

          {/*
		  <div className = {classNames(styles.item_name,styles.text)} >
		  { physicalItemPresent && <p className = {classNames(styles.text)} >Votre commande contient au moins un produit à envoyer</p> }
		  </div>
		  */}
		  
		  <div className = {classNames(styles.item_unit_price,styles.text)} >
		  </div>

		  <div className = {classNames(styles.quantity,styles.text)} >
		   <div>
		     Frais de port :
 		   </div>
		  </div>

		  <div className = {classNames(styles.item_price,styles.text)} >
		    <div className = {classNames(styles.text,styles.right_align)} >
  	          {(shippingFees).toFixed(2)} €
		    </div>
		  </div>

		</div>

	    <br/>
		
	    <div className = {classNames(styles.grid)} >
		
		  <div className = {classNames(styles.item_image)} >
		  </div>

		  <div className = {classNames(styles.item_name,styles.text)} >
		  </div>
		
		  <div className = {classNames(styles.item_unit_price,styles.text)} >
		  </div>

		  <div className = {classNames(styles.quantity,styles.text)} >
		   <div>
		     Total TTC :
 		   </div>
		  </div>

		  <div className = {classNames(styles.item_price,styles.text)} >
		    <div className = {classNames(styles.text,styles.right_align)} >
  	          {(totalPrice/100 + shippingFees).toFixed(2)} €
		    </div>
		  </div>

		</div>
	  </>
	
	)

    else 
	 return (<></>)
  }
  
  const handleOrderClick = () => {
  
    if ( authentication.token === "" )
	      {
	       setAlertWindowVisible1 ( true );
	      }
	 else {
	       if ( isTokenValid ( authentication.token ) === false )
	             {
	              showAlertWindowDeprecatedToken ()
	             }
			else {
	              if ( user.firstname === "" ||
                       user.lastname  === "" ||
                       user.address   === "" ||
                       user.zipCode   === "" ||
                       user.city      === "" )
			            {
	                     setAlertWindowVisible2 ( true );
		   	            }
	    		   else {
			             setShowBankChoice ( true )
			            }
	             }
	      }
  }
  
  const closeAlertWindowWithCancelButton = () => {
  
    setAlertWindowVisible1 ( false );
    setAlertWindowVisible2 ( false );
  }

  const closeAlertWindowWithValidButton1 = () => {
  
  
    setAlertWindowVisible1 ( false );
	
    navigate ( `${INTERNAL_LINKS.Login}`)
  }

  const closeAlertWindowWithValidButton2 = () => {
  
  
    setAlertWindowVisible2 ( false );
	
	if ( authentication.token !== '' )
	
       	 { navigate ( `${INTERNAL_LINKS.AccountManagment}`) }
	else { navigate ( `${INTERNAL_LINKS.Login}`) }
  }

  const handleCancelButtonClickInBankChoice = () => {
  
    setShowBankChoice (false)
  }

  const handleValidButtonClickInBankChoice = ( codeBank: string ) => {
     
    setShowBankChoice (false)
	
	makePayment ( codeBank )
  }
  
  const makePayment = async ( codeBank: string ) => {
  
      const amount: number = totalPrice + ( physicalItemPresent ? shippingFees * 100 : 0 )
  
	  postEvent ( `Tentative paiement codeBank = {codeBank} ${amount}` , authentication.login );

      const response = await postPayment ( amount                  , // Montant en centimes d'euros
			                               cart.purchaseId         , // Code du prix appliquée à cette vente
										   codeBank                , // Code de banque 01 = "BNP - Axepta" et 02 = "PayPal"
                                           authentication.login    , // Email du user
										   "Boutique Lovelight.TV" , // Commentaire
                                           authentication.token    ) // Token JWT

      console.log ( "makePayment ", codeBank, response)

    if ( response.code === 1 )
	 {
	  window.location.href = response.url;
	 }

    /*
    switch ( response.code )
	 {
      case 1 : // Dès que le paiement a été créé, on ressort automatiquement pour ne pas rester en mode connecté
               // (le user doit valider son email avant de se connecter)

                  
			   
			      break;
			   
	  case -102 : setWindowMessage (<>Votre sessions a expiré, veuillez vous reconnecter</>);
	              setShowWindow    (true)
                  setExpiredSession (true);
				  
				  break;

      default :   setWindowMessage (<>Une erreur réseau est survenue lors de la génération de la demande de paiement</>);
	              setShowWindow    (true)
	 }
	*/
  }  
  
    const showAlertWindowDeprecatedToken = () => {
  
    setAlertWindowDeprecatedTokenVisible ( true );
  }

  const closeAlertWindowDeprecatedTokenWithCancelButton = () => {

    setAlertWindowDeprecatedTokenVisible ( false );
	
	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Home}` );
   }

  const closeAlertWindowDeprecatedTokenWithValidButton = () => {

    setAlertWindowDeprecatedTokenVisible ( false );

	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Login}` );
  }

  
  return (
            <>
		      
 		      <CookiesManager />
			  <GlobalMenu version={version}/>
              <TopShifter/>

		      {
	           alertWindowVisible1 &&
	           ( < Window message        = { <><span className = {classNames(styles.bold_text)}>Vous devez être connecté pour finaliser votre commande.</span><br/><br/>Se connecter ?<br/></> }
                   messageType           = "normal"
                   onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                   onClickOnValidButton  = {closeAlertWindowWithValidButton1}
                   cancelButtonName      = "NON"
                   validButtonName       = "OUI"   /> )
	          }

		      {
	           alertWindowVisible2 &&
	           ( < Window message        = { <><span className = {classNames(styles.bold_text)}>Vous devez saisir votre adresse postale pour finaliser votre commande.</span><br/><br/>Compléter mon adresse ?<br/></> }
                   messageType           = "normal"
                   onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                   onClickOnValidButton  = {closeAlertWindowWithValidButton2}
                   cancelButtonName      = "NON"
                   validButtonName       = "OUI"   /> )
	          }
			  
			  {
	           alertWindowDeprecatedTokenVisible 
			   &&
	           ( 
			    < Window message               = { <><span className = {classNames(styles.bold_text)}>Votre session a expiré, vous devez vous reconnecter.</span><br/><br/>Confirmer ?<br/></> }
                         messageType           = "normal"
                         onClickOnCancelButton = {closeAlertWindowDeprecatedTokenWithCancelButton}
                         onClickOnValidButton  = {closeAlertWindowDeprecatedTokenWithValidButton}
                         cancelButtonName      = "NON"
                         validButtonName       = "OUI"   /> 
			   )
	          }
			  
			  {
			     showBankChoice
				 &&
                 (
				   < BankChoice onCancelButtonClick = { handleCancelButtonClickInBankChoice }
				                onValidButtonClick  = { handleValidButtonClickInBankChoice  } />
				 )
		       }

              <div className = {classNames(styles.root)} >

                {
                  cart.items.length > 0
				
				  ?
				  
				  (
                    <div className = {classNames(styles.contain)} >
                 
				      {renderCart()}
                      {renderTotal()}
		              {renderTotalWithShippingFees()}
				 
		              <br/>
		         
				      <div className = {classNames(styles.centered_block)} >
				
				        <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		                        onClick   = {handleOrderClick} >
			 
   			              <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                          <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#FFFF00] px-3 py-1 text-xl font-medium text-black backdrop-blur-3xl">
                             Finaliser la commande
                          </span>
          
    		            </button>
				  
			          </div>	  
			
                    </div>
				  )
				  
				  :
				
				  (
				    <div className = {classNames(styles.contain)} >
				   
				      <div className = {classNames(styles.centered_block)} >
                        <p className = {classNames(styles.big_message)} >
					      Votre panier est vide.
					    </p>
                      </div>
					  
                    </div>
				  )
				
				}
				
              </div>
			  
			  <Footer/>

            </>
        );
};


export default CartContent;
