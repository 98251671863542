import   React                 ,  
       { useState              ,
	     useEffect             }  from 'react';

import { useNavigate           ,
         useLocation            } from 'react-router-dom';

import { Dispatch              }  from 'redux';

import { useDispatch           }  from 'react-redux';

import { parse, differenceInYears, isValid } from 'date-fns';

import   classNames               from 'classnames';

import   styles                   from './urban_tour_registration.module.scss';

import { useTypedSelector      }  from '../../../../datas/useTypeSelector';

import { GlobalMenu       }  from '../../../menu/global/global';
import { Footer           }  from '../../../menu/footer/footer';

import { TopShifter         }  from '../../../menu/top_shifter/top_shifter';
import { CookiesManager     }  from '../../../cookie/manager';
import { Window             }  from '../../../widget/window/window';
import { WindowWithForm     }  from '../../../widget/window/window_with_form';

//import { PdfViewer   }  from '../../../widget/pdf_viewer/pdf_viewer';

import { RegistrationForm }  from './registration_form'

import { INTERNAL_LINKS   }  from '../../../../network/urls';

import { postEvent        }  from '../../../../network/api_events_stats';
import { isTokenValid     }  from '../../../../network/utils'; 

import { initCart           }  from '../../../../datas/cartManagment/actionCreators';
import { initAuthentication }  from '../../../../datas/authenticationManagment/actionCreators';
import { initUser               }  from '../../../../datas/userManagment/actionCreators';
import { postInformationRequest }  from '../../../../network/api_urban_tour';


export const UrbanTour_Registration = () => {

  const navigate                = useNavigate();
  const dispatch: Dispatch<any> = useDispatch()

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { user           } = useTypedSelector ( (state) => state.userManagment           );
  
  const [ isSmallScreen         , setIsSmallScreen      ] = useState<boolean>(false);

  const [ alertWindowVisible , setAlertWindowVisible ] = useState ( false );
  const [ alertWindowDeprecatedTokenVisible, setAlertWindowDeprecatedTokenVisible] = useState ( false );

  const [ alertInformationRequestWindowVisible , setAlertInformationRequestWindowVisible ] = useState ( false );

  const location    = useLocation();
  const params      = new URLSearchParams(location.search);
  
  const destination = params.get('destination'); // Récupère la valeur de 'destination'

  const switchWidthLimit = 870;

  const handleResize = () => {
    setIsSmallScreen ( window.innerWidth <= switchWidthLimit );
  };

  useEffect(() => {

  }, [isSmallScreen]); 


  useEffect(() => {

/*
    let status : number = -1; // Non connecté

    if ( ( authentication.login !== ""   &&
           authentication.token !== "" ) )
     {
	  if ( isTokenValid ( authentication.token ) === false )
	   {
	    showAlertWindowDeprecatedToken ()
	   }

      status = 0;

      if ( user.status  === "Standard" )  { status = 1 }
      if ( user.status  === "Premium"  )  { status = 2 }
     }

    if ( status !== -1)
          {
		   if ( isTokenValid ( authentication.token ) !== false )
		    {
	         postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

             window.addEventListener('resize', handleResize);

             handleResize();

             return () => {
                window.removeEventListener('resize', handleResize);
             };
	
	         window.scroll(0, 0);
			}
          }
     else {
	       showAlertWindow ()
          }
*/

	         postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

             window.addEventListener('resize', handleResize);

             handleResize();

             return () => {
                window.removeEventListener('resize', handleResize);
             };
	
	         window.scroll(0, 0);

  }, []); 

  const handleInformationRequest = () => {

    const eventMessage: string = "UrbanTour-Registration-Part01-Button01:\"Demander la brochure\""

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertInformationRequestWindowVisible ( true );
  }

/*
  const closeAlertInformationRequestWindowWithCancelButton = () => {

    setAlertInformationRequestWindowVisible ( false );
  }

  const closeAlertInformationRequestWindowWithValidButton = ( firstname: string ,
                                                              lastname:  string ,
                                                              age:       number ,
                                                              email:     string ,
                                                              city:      string ) => {

    setAlertInformationRequestWindowVisible ( false );

    postInformationRequest ( firstname, lastname, age, email, city,  "Lovelight Urban Tour spécial " + ( destination === null ? "" : ( destination as string ) ) )
  }
*/
{/*
  const handleDetails = () => {

    const eventMessage: string = "UrbanTour-Registration-Button01:\"Tarif et détails du séjour\":" + destination 

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    window.open ( getPdfUrl (), '_blank');
  }
*/}
  const renderDestination_Nice = (): JSX.Element => {
  
    return (
	  <>
	    <div className={classNames(styles.description)} >
		 
         <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400 text-center">
            Urban Tour Nice
         </p>
		 
         <br/>

		 <img src="/images/urbantour/Nice.jpg" alt="Nice"  className={classNames(styles.image)} />
		 
		 {/*
		 <div className={classNames(styles.btn)}>

	       <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		                   onClick   = {handleInformationRequest}>
                     <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                     <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-xl font-medium text-white backdrop-blur-3xl">
                       Téléchargez la brochure
                     </span>
                   </button>
				   
         </div>
		 */}	  
		 {/*
		 <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
            1290 €
         </p>
		 
		 <br/>
		 
            <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Ce prix comprend :
<ul className="list-disc list-inside mt-4"> 
               <li>Ateliers photo Lovelight Urban Tour</li>
               <li>Cocktail VIP de bienvenu</li>
			   <li>2 nuits à l'hôtel WindsoR**** chambre single + petits-déjeuners</li>
               <li>Assurance annulation</li>
               <li>Frais Service/Tourisme non remboursable</li>
               <li>1 déjeuner + 1 diner avec l'équipe Lovelight</li>
			  </ul>
            </p>

		 <br/>

            <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Ce prix ne comprend pas :
<ul className="list-disc list-inside mt-4"> 
               <li>les vols à destination de Nice (En option sur demande auprès de notre partenaire Groupe Seemore)</li>
               <li>les transferts aéroport (En option sur demande auprès de notre partenaire Groupe Seemore)</li>
               <li>les dépenses personnelles</li>
               <li>les repas et boissons (hors petit-déjeuner et repas précisés dans le programme)</li>
			  </ul>
            </p>

		 <br/>
*/}
		 <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
            Informations complémentaires
         </p>
        
         <br/>
		 
         <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              L’ensemble de la Lovelight Masterclass Urban Tour Nice est commercialisé par notre partenaire :
         </p>
         <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Groupe Seemore - 11 avenue Guillabert - 06600 Antibes<br/>
              tél : 04 93 34 62 50 / mail : antibes@seemore.fr<br/>
              SARL - Capital : 99 092 Euros<br/>
		      Licence Immatriculation : 006120047<br/>
              APE 7911Z - SIREN 036620250 - TVA INTRA FR17036620250<br/> 
              Garantie Financière : 300 500 Euros - APST - 15 ave Carnot - 75017 PARIS
         </p>
         <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Ce formulaire de préinscription sera directement transmis à notre partenaire Antibes Voyage (Group Seemore) 
		      qui vous transmettra un lien d’accès aux conditions générales de vente, à la validation de votre contrat par 
		      signature électronique et le lien pour procéder au règlement de votre Masterclass.
         </p>
				  
		
        </div>		
	  </>
    )
  }

//		<PdfViewer url="https://custom-front.preprod.lovelightfactory.fr/pdf/Urban_Brochure_Nice.pdf"/>


  const renderDestination_Rome = (): JSX.Element => {
  
    return  (
	  <>
	    <div className={classNames(styles.description)} >
		 
         <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400 text-center">
            Urban Tour Rome
         </p>
		 
         <br/>

		 <img src="/images/urbantour/Rome.jpg" alt="Rome"  className={classNames(styles.image)} />

         {/*		 
		 <div className={classNames(styles.btn)}>

	       <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		                   onClick   = {handleInformationRequest}>
                     <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                     <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 px-3 py-1 text-xl font-medium text-white backdrop-blur-3xl">
                       Tarif et détails du séjour
                     </span>
                   </button>
				   
         </div>
		 */}	  
		 {/*
		 <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
            1790 €
         </p>
		 
		 <br/>
		 
            <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Ce prix comprend :
<ul className="list-disc list-inside mt-4"> 
               <li>Ateliers photo Lovelight Urban Tour</li>
               <li>Cocktail VIP de bienvenu</li>
			   <li>2 nuits à l'hôtel 9Hotel Cesari**** chambre single + petits-déjeuners</li>
               <li>Assurance annulation</li>
               <li>Frais Service/Tourisme non remboursable</li>
               <li>1 déjeuner + 1 diner avec l'équipe Lovelight</li>
			  </ul>
            </p>

		 <br/>

            <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Ce prix ne comprend pas :
<ul className="list-disc list-inside mt-4">
               <li>les vols à destination de Rome (En option sur demande auprès de notre partenaire Groupe Seemore)</li>
               <li>les transferts aéroport (En option sur demande auprès de notre partenaire Groupe Seemore)</li>
               <li>les dépenses personnelles</li>
               <li>les repas et boissons (hors petit-déjeuner et repas précisés dans le programme)</li>
			  </ul>
            </p>

		 <br/>
         */}
		 <p className="text-3xl font-bold text-white text-neutral-600 dark:text-neutral-400">
            Informations complémentaires
         </p>
        
         <br/>
		 
         <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              L’ensemble de la Lovelight Masterclass Urban Tour Rome est commercialisé par notre partenaire :
         </p>
         <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Groupe Seemore - 11 avenue Guillabert - 06600 Antibes<br/>
              tél : 04 93 34 62 50 / mail : antibes@seemore.fr<br/>
              SARL - Capital : 99 092 Euros<br/>
		      Licence Immatriculation : 006120047<br/>
              APE 7911Z - SIREN 036620250 - TVA INTRA FR17036620250<br/> 
              Garantie Financière : 300 500 Euros - APST - 15 ave Carnot - 75017 PARIS
         </p>
         <p className="text-justify text-xl font-regular text-white text-neutral-600 dark:text-neutral-400">
              Ce formulaire de préinscription sera directement transmis à notre partenaire Antibes Voyage (Group Seemore) 
		      qui vous transmettra un lien d’accès aux conditions générales de vente, à la validation de votre contrat par 
		      signature électronique et le lien pour procéder au règlement de votre Masterclass.
         </p>
				  
		
        </div>		
	  </>
    )
  }

  const renderDestination_Nowhere = (): JSX.Element => {
  
    return  (
	  <>
	    <span style={{color: '#FFFFFF'}}>DESTINATION INCONNUE</span>
	  </>
    )
  }

  const renderDestination = (): JSX.Element => {
  
    switch ( destination )
	 {
	  case encodeURIComponent ("Nice") : return renderDestination_Nice ()
	                break;
					
	  case encodeURIComponent ("Rome") : return renderDestination_Rome ()
	                break;
					
	  default :     return renderDestination_Nowhere ()
	                break;
	 }
  }

  const getSessionDates = (): string [] => {
  
    let tab: string [] = []
  
    switch ( destination )
	 {
	  case "Nice" : tab.push ("du 31/01/2025 au 02/02/2025")
	                break;
					
	  case "Rome" : tab.push ("du 05/03/2025 au 09/03/2025")
	                break;
					
	  default :     break;
	 }
	 
    return tab
  }

  const getPdfUrl = (): string => {
  
    switch ( destination )
	 {
	  case "Nice" : return "https://custom-front.preprod.lovelightfactory.fr/pdf/Brochure_Urban_Tour_Nice.pdf"
	                break;
					
	  case "Rome" : return "https://custom-front.preprod.lovelightfactory.fr/pdf/Brochure_Urban_Tour_Rome.pdf"
	                break;
					
	  default :     return ""
	 }
  }

  const renderSmallSizeComponent = (): JSX.Element => {

    return  <>
	          
              <div className={classNames(styles.layout_2)}>
                <div className={classNames(styles.layout_2_block_text)} >
                   {renderDestination()}
				</div>
                <div className={classNames(styles.layout_2_block_form)} >
                   <RegistrationForm destination  = {destination}
				                     dates        = {getSessionDates()} />
				</div>
			  </div>
	
	        </>
  }


  const renderBigSizeComponent = (): JSX.Element => {

    return  <>
	
              <div className={classNames(styles.layout_1)}>
                <div className={classNames(styles.layout_1_block_text)} >
                   {renderDestination()}
				</div>
                <div className={classNames(styles.layout_1_block_form)} >
                   <RegistrationForm destination  = {destination}
				                     dates        = {getSessionDates()} />
				</div>
			  </div>

	        </>
  }

  const showAlertWindow = () => {
  
  /*
    if ( authentication.token !== '' )
	      {
		   if ( isTokenValid ( authentication.token ) !== false )
            {
             const eventMessage: string = `UrbanTour-Registration-Window:\"Vous devez être un abonné standard ou premium pour accéder à cette masterclass\"`

             postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
			 
             setAlertWindowVisible ( true );
			}
	      }
	 else {
           const eventMessage: string = `UrbanTour-Registration-Window:\"Vous devez être connecté pour accéder à cette masterclass\"`

           postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
		   
           setAlertWindowVisible ( true );
	      }
  */

    if ( authentication.token !== '' )
	      {
           const eventMessage: string = `UrbanTour-Registration-Window:\"Vous devez disposer d’un compte gratuit\"`

           postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
		   
           setAlertWindowVisible ( true );
	      }
  
  }

/*
  const closeAlertWindowWithCancelButton = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window-Button_NON`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowVisible ( false );
  }

  const closeAlertWindowWithValidButton1 = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.Login}`)
  }

  const closeAlertWindowWithValidButton2 = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.AccountManagment_Subscription}`)
  }
*/

  const closeAlertWindowWithCreateAccountButton = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window-Button_CREATE_ACOUNT`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.Subscription}`)
  }

  const closeAlertWindowWithLoginButton = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window-Button_LOGIN`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )
  
    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.Login}`)
  }



  const showAlertWindowDeprecatedToken = () => {
  
    const eventMessage: string = `UrbanTour-Registration-Window:\"Votre session a expiré, vous devez vous reconnecter.\"`

    postEvent ( "__SHOW__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( true );
  }

  const closeAlertWindowDeprecatedTokenWithCancelButton = () => {

    const eventMessage: string = `UrbanTour-Registration-Window-Button_NON`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( false );
	
	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Home}` );
   }

  const closeAlertWindowDeprecatedTokenWithValidButton = () => {

    const eventMessage: string = `UrbanTour-Registration-Window-Button_OUI`

    postEvent ( "__CLICK__:" + window.location.pathname + window.location.search + window.location.hash + ':' + eventMessage , authentication.login )

    setAlertWindowDeprecatedTokenVisible ( false );

	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Login}` );
  }

  function calculateAge(): string {
  
    const birthDate = parse(user.birthday, 'dd/MM/yyyy', new Date());
 
    if (!isValid(birthDate)) 
	 {
      throw new Error("Le format de la date de naissance est invalide.");
     }

    const age = differenceInYears(new Date(), birthDate);
  
    return age.toString();  
  }

  return (
            
            <>
		      
 		      <CookiesManager />
 			  <GlobalMenu />
              <TopShifter/>

	          {/*
	            alertWindowVisible &&
		        authentication.token === '' &&
	            ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être connecté pour accéder à cette offre.</span><br/><br/>Se connecter ?<br/></> }
                           messageType           = "normal"
                           onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                           onClickOnValidButton  = {closeAlertWindowWithValidButton1}
                           cancelButtonName      = "NON"
                           validButtonName       = "OUI"   /> )
	          */}
			
	          {/*
	            alertWindowVisible &&
		        authentication.token !== '' &&
	            ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être un abonné standard ou premium pour accéder à cette offre.</span><br/><br/>S'abonner ?<br/></> }
                           messageType           = "normal"
                           onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                           onClickOnValidButton  = {closeAlertWindowWithValidButton2}
                           cancelButtonName      = "NON"
                           validButtonName       = "OUI"   /> )
	          */}
			  
	          {
	            alertWindowVisible &&
		        authentication.token === '' &&
	            ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez disposer d’un compte gratuit</span><br/></> }
                           messageType           = "normal"
                           onClickOnCancelButton = {closeAlertWindowWithLoginButton}
                           onClickOnValidButton  = {closeAlertWindowWithCreateAccountButton}
                           cancelButtonName      = "Se connecter"
                           validButtonName       = "Créer un compte gratuit"   /> )
	          }
			  
		
			  {
	           alertWindowDeprecatedTokenVisible 
			   &&
	           ( 
			    < Window message               = { <><span className = {classNames(styles.bold_text)}>Votre session a expiré, vous devez vous reconnecter.</span><br/><br/>Confirmer ?<br/></> }
                         messageType           = "normal"
                         onClickOnCancelButton = {closeAlertWindowDeprecatedTokenWithCancelButton}
                         onClickOnValidButton  = {closeAlertWindowDeprecatedTokenWithValidButton}
                         cancelButtonName      = "NON"
                         validButtonName       = "OUI"   /> 
			   )
	          }
			  
			  {/*
	            alertInformationRequestWindowVisible &&
	            ( < WindowWithForm  message               = { <><span className = {classNames(styles.bold_text)}>Je souhaite recevoir par mail des information concernant l'offre "Urban Tour {destination}".</span><br/></> }
                                    messageType           = "normal"
                                    onClickOnCancelButton = {closeAlertInformationRequestWindowWithCancelButton}
                                    onClickOnValidButton  = {closeAlertInformationRequestWindowWithValidButton}
                                    cancelButtonName      = "NON"
                                    validButtonName       = "OUI"
							        stylesGridInfo        = {classNames(styles.grid_info)} 
									initialFirstname      = {user.firstname}
								    initialLastname       = {user.lastname}
								    initialAge            = {calculateAge()}
								    initialEmail          = {user.email}
								    initialCity           = {user.city}  /> )
	          */}

           <div className={classNames(styles.root)}>


			 { 
			  ( isSmallScreen ? renderSmallSizeComponent() : renderBigSizeComponent () ) 
			 }


           </div>

			<Footer/>

            </>
           
         );
};


export default UrbanTour_Registration;
