import   axios                       from 'axios';

import { Product                  ,
         ProductVariant           ,
		 Characteristic           ,
		 Price                    ,
		 ProductCategory          ,
		 ProductCatalog           ,
		 Discount                 }  from '../datas/productManagment/actionTypes';

import { getUserAgentInformations ,
         getAppInformations       }  from './utils';	   

import { API_URLS                 }  from './urls';


export interface ProductInfos {
  code:      number ,
  comment:   string ,
  products:  Product[]
}

/*

"Tous"
"Avec inscription obligatoire"
"Offres gratuites"
"Offres payantes"

*/

export const getProductInfos = async ( rawFilter: string ) : Promise<ProductInfos>  => {

    const baseUrl = `${API_URLS.getProductInfos}?`;

    const name1  = "filter="; 
    const value1 = rawFilter !== '' ? encodeURIComponent (rawFilter) : encodeURIComponent ("Tous") ;      

    var url = `${baseUrl}${name1}${value1}`;

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
          'User-Agent-Informations': `${userAgentInformations}`,
	      'App-Informations': `${appInformations}`,
    };

    var result:ProductInfos = {
      code:     0 ,
	  comment:  "" ,
      products: []
    };

    console.log ("getProductInfos", url)

    try {

      const response = await axios.get ( url, { headers: headers } );
  
      const statut = response.status;

      if ( statut === 200  )
             {
			  const rawInfos_products = response.data
			  
			  let products: Product [] = []
			  
			  for (let i = 0; i < rawInfos_products.length; i++) 
			   {
                const rawInfos_product = rawInfos_products[i];

		        let product: Product = {
					    id:                   rawInfos_product.id   ,
                        name:                 rawInfos_product.name ,
                        catchword:            rawInfos_product.catchword ,
						code:                 rawInfos_product.productCode ,
						active:               rawInfos_product.active ,
						requiredStatus:       rawInfos_product.requiredStatus, // gratuit, découverte, standard, premium
						paymentRequired:      rawInfos_product.paymentRequired, // gratuit, découverte, standard, premium
						kind:                 rawInfos_product.kind,           // PhysicalItem, VideoMasterClass..
						description:          rawInfos_product.description,
                        creationDate:         rawInfos_product.creationDate,
						variants:             [],
						characteristics:      []
				    }

			    for (let j = 0; j < rawInfos_product.productVariants.length; j++) 
			     {
                  const rawInfos_productVariant = rawInfos_product.productVariants[j];

 
		          let variant: ProductVariant = {
				  
				        id:                 rawInfos_productVariant.id ,
 				        name:               rawInfos_productVariant.name ,
				        complement:         rawInfos_productVariant.complement ,
						description:        rawInfos_productVariant.description ,
						barcode:            rawInfos_productVariant.barcode ,
						active:             rawInfos_productVariant.active ,
                        creationDate:       rawInfos_productVariant.creationDate ,
 				        businessUnitCode:   rawInfos_productVariant.businessUnitCode ,
 				        productVariantCode: rawInfos_productVariant.productVariantCode ,
                        stockAvailable:     rawInfos_productVariant.stockAvailable ,
                        stockBooked:        rawInfos_productVariant.stockBooked ,
                        stockAssigned:      rawInfos_productVariant.stockAssigned ,
                        stockThreshold:     rawInfos_productVariant.stockThreshold ,
				        characteristics:    [],
						prices:             []
				    }
					
                  for (let k = 0; k < rawInfos_productVariant.characteristics.length; k++) 
			       {
                    const rawInfos_characteristic2 = rawInfos_productVariant.characteristics[k];

		            let characteristic2: Characteristic = {
				  
				        id:    rawInfos_characteristic2.id ,
 				        name:  rawInfos_characteristic2.name ,
 				        kind:  rawInfos_characteristic2.kind ,
				        unit:  rawInfos_characteristic2.unit ,
 				        value: rawInfos_characteristic2.value 
				      }
					
				    variant.characteristics.push ( characteristic2 )
                   }

                  for (let l = 0; l < rawInfos_productVariant.prices.length; l++) 
			       {
                    const rawInfos_price = rawInfos_productVariant.prices[l];


		            let price: Price = {
				  
                        id:                            rawInfos_price.id                            ,
                        name:                          rawInfos_price.name                          ,
                        requiredStatus:                rawInfos_price.requiredStatus                ,
                        comment:                       rawInfos_price.comment                       ,
                        code:                          rawInfos_price.code                          ,
						active:                        rawInfos_price.active                          ,
                        creationDate:                  rawInfos_price.creationDate                  ,
                        basePrice:                     rawInfos_price.basePrice                     ,
                        taxRate:                       rawInfos_price.taxRate                       ,
                        priceToPay:                    rawInfos_price.priceToPay                    ,
                        priceWithTax:                  rawInfos_price.priceWithTax          ,
						discounts:                     []                         
						}
						
						for (let k = 0; k < rawInfos_price.discounts.length; k++) 
			             {
                          const rawInfos_discount = rawInfos_price.discounts[k];

		                  let discount: Discount = {
						    type           : rawInfos_discount.type,
                            condition      : rawInfos_discount.condition,
                            value          : rawInfos_discount.value,
                            active         : rawInfos_discount.active,
                            priceToApplyOn : rawInfos_discount.priceToApplyOn
						  }
						  
				          price.discounts.push ( discount )
						 }
						 
				      
					
				    variant.prices.push ( price )
                   }
				   
					
				  product.variants.push ( variant )
                 }

			    for (let j = 0; j < rawInfos_product.characteristics.length; j++) 
			     {
                  const rawInfos_characteristic = rawInfos_product.characteristics[j];

		          let characteristic: Characteristic = {
				  
				        id:    rawInfos_characteristic.id ,
 				        name:  rawInfos_characteristic.name ,
 				        kind:  rawInfos_characteristic.kind ,
				        unit:  rawInfos_characteristic.unit ,
 				        value: rawInfos_characteristic.value 
				    }
					
				  product.characteristics.push ( characteristic )
                 }
				 
                products.push ( product )
			   }
			   
			  result.products = products;
             }
        else {
		
              result = {
                code:     -101,
                comment:  `Erreur lors de la récupération des informations des abonnements : ${response.status}`,
                products: []
              };
             }


    }
    catch (error) {

     result = {
       code:     -201,
 	   comment:  "Erreur réseau",
       products: []
     };
    }
	
    return result;
  };


/*
export interface ProductCatalogInfos {
  code:     number,
  comment:  string,
  catalog:  ProductCatalog
}

export const getProductCatalogInfos = async () : Promise<ProductCatalogInfos>  => {

    const url = `${API_URLS.getProductCatalogInfos}`;

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
          'User-Agent-Informations': `${userAgentInformations}`,
	      'App-Informations': `${appInformations}`,
    };

    var result:ProductCatalogInfos = {
      code:    0 ,
	  comment: "" ,
      catalog: {
        categories: []
      }
    };

    console.log ("getProductCatalogInfos", url)

    try {

      const response = await axios.get ( url, { headers: headers } );
	  
     console.log ("getProductCatalogInfos" , response )
      const statut = response.status;

      if ( statut === 200  )
             {
			  const rawInfos_catalog = response.data
			  
			  let catalog: ProductCatalog = {
                    categories: []
              }
			  

			  
			  for (let i = 0; i < rawInfos_catalog.length; i++) 
			   {
                const rawInfos_category = rawInfos_catalog[i];

			    let category: ProductCategory = {
                      id:              rawInfos_category.id ,
                      name:            rawInfos_category.name ,
                      rank:            rawInfos_category.rank ,
                      creationDate:    rawInfos_category.creationDate ,
                      visible:         rawInfos_category.visible ,
                      products:        [] 	}

			    for (let j = 0; j < rawInfos_category.products.length; j++) 
			     {
                  const rawInfos_product = rawInfos_category.products[j];

		          let product: Product = {
					    id:                   rawInfos_product.id   ,
                        name:                 rawInfos_product.name ,
						catchword:            rawInfos_product.catchword , 
						code:                 rawInfos_product.productCode ,
						active:               rawInfos_product.active ,
						requiredStatus:       rawInfos_product.requiredStatus, // gratuit, découverte, standard, premium
						paymentRequired:      rawInfos_product.paymentRequired, // gratuit, découverte, standard, premium
						kind:                 rawInfos_product.kind,           // PhysicalItem, VideoMasterClass..
                        description:          rawInfos_product.description,
                        creationDate:         rawInfos_product.creationDate,		
						variants:             [],
						characteristics:      []
				    }
					
			    for (let j = 0; j < rawInfos_product.productVariants.length; j++) 
			     {
                  const rawInfos_productVariant = rawInfos_product.productVariants[j];

		          let variant: ProductVariant = {
				  
				        id:                 rawInfos_productVariant.id ,
 				        name:               rawInfos_productVariant.name ,
				        complement:         rawInfos_productVariant.complement ,
                        description:        rawInfos_productVariant.description,
                        barcode:            rawInfos_productVariant.barcode,
                        active:             rawInfos_productVariant.active,
                        creationDate:       rawInfos_productVariant.creationDate,
 				        businessUnitCode:   rawInfos_productVariant.businessUnitCode ,
 				        productVariantCode: rawInfos_productVariant.productVariantCode ,
 				        stockAvailable:     rawInfos_productVariant.stockAvailable ,
 				        stockBooked:        rawInfos_productVariant.stockBooked ,
 				        stockAssigned:      rawInfos_productVariant.stockAssigned ,
 				        stockThreshold:     rawInfos_productVariant.stockThreshold ,
						characteristics:    [],
						prices:             []
				    }
					
                  for (let k = 0; k < rawInfos_productVariant.characteristics.length; k++) 
			       {
                    const rawInfos_characteristic2 = rawInfos_productVariant.characteristics[k];

		            let characteristic2: Characteristic = {
				  
				        id:    rawInfos_characteristic2.id ,
 				        name:  rawInfos_characteristic2.name ,
 				        kind:  rawInfos_characteristic2.kind ,
				        unit:  rawInfos_characteristic2.unit ,
 				        value: rawInfos_characteristic2.value 
				      }
					
				    variant.characteristics.push ( characteristic2 )
                   }
					
                  for (let l = 0; l < rawInfos_productVariant.prices.length; l++) 
			       {
                    const rawInfos_price = rawInfos_productVariant.prices[l];

		            let price: Price = {
				  
                        id:                            rawInfos_price.id ,
                        name:                          rawInfos_price.name ,
                        active:                        rawInfos_price.active,
                        requiredStatus:                rawInfos_price.requiredStatus ,
                        comment:                       rawInfos_price.comment ,
                        code:                          rawInfos_price.code ,
                        creationDate:                  rawInfos_price.creationDate,
                        basePrice:                     rawInfos_price.basePrice ,
                        taxRate:                       rawInfos_price.taxRate ,
                        priceWithTax:                  rawInfos_price.priceWithTax ,
                        priceToPay:                    rawInfos_price.priceToPay,
						discounts:                     []
				      }
					  
						
						for (let k = 0; k < rawInfos_price.discounts.length; k++) 
			             {
                          const rawInfos_discount = rawInfos_price.discounts[k];

		                  let discount: Discount = {
						    type           : rawInfos_discount.type,
                            condition      : rawInfos_discount.condition,
                            value          : rawInfos_discount.value,
                            active         : rawInfos_discount.active,
                            priceToApplyOn : rawInfos_discount.priceToApplyOn
						  }
						  
				          price.discounts.push ( discount )
						 }					  
					
				    variant.prices.push ( price )
                   }
					
				  product.variants.push ( variant )
                 }

			    for (let j = 0; j < rawInfos_product.characteristics.length; j++) 
			     {
                  const rawInfos_characteristic = rawInfos_product.characteristics[j];

		          let characteristic: Characteristic = {
				  
				        id:    rawInfos_characteristic.id ,
 				        name:  rawInfos_characteristic.name ,
 				        kind:  rawInfos_characteristic.kind ,
				        unit:  rawInfos_characteristic.unit ,
 				        value: rawInfos_characteristic.value 
				    }
					
				  product.characteristics.push ( characteristic )
                 }
				 
				category.products.push (product)
				  
				  
                 }
				 
				catalog.categories.push ( category );
 			   }
			   
			  result.catalog = catalog;
			  
     console.log ("getProductCatalogInfos" , result )
             }
        else {
              result = {
                code:    -101,
                comment: `Erreur lors de la récupération des informations de paiement de l'utilisateur : ${response.status}`,
                catalog: {
                  categories: []
                }
              };
             }


    }
    catch (error) {

     result = {
       code:    -201,
 	   comment: "Erreur réseau",
       catalog: {
         categories: []
       }
     };
    }

     console.log ("getProductCatalogInfos" , result )

    return result;
  };

*/


export interface ProductCatalogInfos {
  code: number;
  comment: string;
  catalog: ProductCatalog;
}

export const getProductCatalogInfos = async (): Promise<ProductCatalogInfos> => {
  const url = `${API_URLS.getProductCatalogInfos}`;

  const headers = {
    'User-Agent-Informations': getUserAgentInformations(),
    'App-Informations': getAppInformations(),
  };

  let result: ProductCatalogInfos = {
    code: 0,
    comment: "",
    catalog: { categories: [] },
  };

  try {
    const response = await axios.get(url, { headers });

  console.log("getProductCatalogInfos", url, response);

    if (response.status === 200) {
      const rawInfosCatalog = response.data;
      result.catalog = parseCatalog(rawInfosCatalog);
    } else {
      result = createErrorResult(-101, `Erreur de récupération des données : ${response.status}`);
    }
  } catch (error) {
    result = createErrorResult(-201, "Erreur réseau");
  }

  console.log("getProductCatalogInfos", result);
  return result;
};

const parseCatalog = (rawCatalog: any[]): ProductCatalog => {
  return {
    categories: rawCatalog.map(parseCategory),
  };
};

const parseCategory = (rawCategory: any): ProductCategory => {
  return {
    id: rawCategory.id || 0,
    name: rawCategory.name || "Nom indisponible",
    rank: rawCategory.rank || 0,
    creationDate: rawCategory.creationDate || "",
    visible: rawCategory.visible === "true",
    products: rawCategory.products.map(parseProduct),
  };
};

const parseProduct = (rawProduct: any): Product => {
  return {
    id: rawProduct.id || 0,
    name: rawProduct.name || "Nom non disponible",
    catchword: rawProduct.catchword || "",
    code: rawProduct.code || "",
    active: rawProduct.active === "true",
    requiredStatus: rawProduct.requiredStatus || "",
    paymentRequired: rawProduct.paymentRequired === "true",
    kind: rawProduct.kind || "",
    description: rawProduct.description || "",
    creationDate: rawProduct.creationDate || "",
    variants: rawProduct.productVariants.map(parseProductVariant),
    characteristics: rawProduct.characteristics.map(parseCharacteristic),
  };
};

const parseProductVariant = (rawVariant: any): ProductVariant => {
  return {
    id: rawVariant.id || 0,
    barcode: rawVariant.barcode || "",
    active: rawVariant.active || false,
    name: rawVariant.name || "",
    complement: rawVariant.complement || "",
    description: rawVariant.description || "",
    creationDate: rawVariant.creationDate || "",
    businessUnitCode: rawVariant.businessUnitCode || "",
    productVariantCode: rawVariant.productVariantCode || "",
    stockAvailable: rawVariant.stockAvailable || 0,
    stockBooked: rawVariant.stockBooked || 0,
    stockAssigned: rawVariant.stockAssigned || 0,
    stockThreshold: rawVariant.stockThreshold || 0,
    characteristics: rawVariant.characteristics.map(parseCharacteristic),
    prices: rawVariant.prices.map(parsePrice),
  };
};

const parseCharacteristic = (rawCharacteristic: any): Characteristic => {
  return {
    id: rawCharacteristic.id || 0,
    name: rawCharacteristic.name || "Non spécifié",
    kind: rawCharacteristic.kind || "",
    unit: rawCharacteristic.unit || "",
    value: rawCharacteristic.value || "",
  };
};

const parsePrice = (rawPrice: any): Price => {
  return {
    id: rawPrice.id || 0,
    name: rawPrice.name || "",
    active: rawPrice.active === "true",
    requiredStatus: rawPrice.requiredStatus || "",
    comment: rawPrice.comment || "",
    code: rawPrice.code || "",
    creationDate: rawPrice.creationDate || "",
    basePrice: rawPrice.basePrice || 0,
    taxRate: rawPrice.taxRate || 0,
    priceWithTax: rawPrice.priceWithTax || 0,
    priceToPay: rawPrice.priceToPay || 0,
    discounts: rawPrice.discounts.map(parseDiscount),
  };
};

const parseDiscount = (rawDiscount: any): Discount => {
  return {
    type: rawDiscount.type || "",
    condition: rawDiscount.condition || "",
    value: rawDiscount.value || 0,
    active: rawDiscount.active === "true",
    priceToApplyOn: rawDiscount.priceToApplyOn || 0,
  };
};

const createErrorResult = (code: number, comment: string): ProductCatalogInfos => {
  return {
    code,
    comment,
    catalog: { categories: [] },
  };
};
